import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import derrickRose from '../images/more-examples/derrick_rose.jpg';
import flower from '../images/more-examples/flower.jpg';
import meadow from '../images/more-examples/meadow.jpg';
import mountains from '../images/more-examples/mountains.jpg';
import waterfall from '../images/more-examples/starry-night-waterfall.png';

function MoreExamplesPage() {
  return (
    <Layout>
      <SEO keywords={['Custom Machine Learning Image']} title="More Examples" />

      <section className="text-center">
        <h1 className="inline-block mb-2 text-4xl font-bold text-white">More Examples</h1>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-3 mt-6 gap-4">
        <div className="col-span-1 flex items-center justify-center">
          <img src={derrickRose} alt="Derrick Rose" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <img src={flower} alt="Flower" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <img src={meadow} alt="A meadow" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <img src={mountains} alt="Mountains" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <img src={waterfall} alt="Waterfall in the Uinta mountains" />
        </div>

        <div className="col-span 1 lg:col-span-3 my-12 text-center">
          <Link
            to="/try-it-now"
            className="text-white bg-transparent hover:bg-blue-300 text-blue-300 font-semibold hover:text-blue-900 py-4 px-8 border border-blue-300 hover:border-transparent rounded"
          >
            Try It Now
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export default MoreExamplesPage;
